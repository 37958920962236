import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInfinitySlider,
  COverflowTable,
  CSimpleCard,
  CTable,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  CHorizCard,
  LBanquetContact,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'Web会議システム',
            sub: <>WEB SYSTEM</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/on-line_meeting/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/on-line_meeting/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="l_sect02 u_pt50">
        <LWrap>
          <p className="c_sectLead u_mb50">
            宴会場のインターネット回線を使用し、ソーシャルディスタンスを確保した広い空間でのWeb会議システムとなります。
            <br />
            対応可能なパソコンをお持込みいただき、社内会議から講演会のライブ配信まで幅広くご利用いただけます。
          </p>
          <CTable
            data={[
              {
                title: '料金',
                content: (
                  <>
                    99,000円～
                    <br />
                    別途、お部屋料と基本音響照明費が発生いたします。
                  </>
                ),
              },
              {
                title: '内容',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>
                        マイク、音声ラインと音響オペレーター、音声中継用機材が含まれます。
                      </li>
                      <li>
                        インターネット回線と有線LAN（最大1,000Mbps）と線材･接続に立会います。
                      </li>
                      <li>
                        中継カメラをご持参の場合は接続が可能でございます。
                      </li>
                      <li>
                        Web会議用USB-TypeCが使用可能なパソコン、ソフトとWeb会議のご相手のインターネット回線とパソコンはあらかじめご用意をお願いいたします。
                      </li>
                      <li>
                        ホテル有線インターネット回線の通信速度は、状況により変動いたします。
                      </li>
                      <li>
                        各種映像機器や音響設備のご用意も承っておりますので、お気軽にご相談ください。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: 'オプション',
                content: (
                  <>
                    <p className="u_mb10">
                      プロジェクター、スクリーン、線材費
                      <br className="u_sp" />
                      99,000円～
                      <br />
                      中継用カメラ　84,700円～
                    </p>
                    <ul className="c_noteList">
                      <li>料金には消費税が含まれます。</li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
